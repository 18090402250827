import { useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { instance } from 'apis';
import { auth, provider } from 'services/firebase';
import { signInWithPopup } from 'firebase/auth';

import { AuthContext } from 'context/AuthContext';

import logo from 'assets/img/HIARex_logo.png';
import { Modal } from 'antd';

export default function Login() {
  const navigate = useNavigate();
  const context = useContext(AuthContext);

  const onGoogleLoginClick = async () => {
    provider.setCustomParameters({ prompt: 'select_account' });
    await signInWithPopup(auth, provider)
      .then((result) => {
        console.log('signInWithPopup result : ', result);
        const user = result.user;
        user
          .getIdToken()
          .then((token) => {
            instance
              .get(`/users/${user.uid}`, {
                headers: {
                  Authorization: token,
                },
              })
              .then((result) => {
                console.log('instance result : ', result);
                context.setIsLoggedIn(true);
                context.setToken(token);
                context.setUserData({
                  ...result.data,
                });
                navigate('/');
                return;
              })
              .catch((error) => {
                console.log('instance error : ', error);
                Modal.error({
                  title: error.response.data.errorCode,
                  content: error.response.data.message,
                  okButtonProps: { className: 'bg-[#1677ff]' },
                });
              });
          })
          .catch((error) => {
            console.log('getIdToken error : ', error);
            Modal.error({
              title: error.response.data.errorCode,
              content: error.response.data.message,
              okButtonProps: { className: 'bg-[#1677ff]' },
            });
          });
      })
      .catch((error) => {
        console.log('signInWithPopup error : ', error);
        Modal.error({
          title: error.response.data.errorCode,
          content: error.response.data.message,
          okButtonProps: { className: 'bg-[#1677ff]' },
        });
      });
  };

  return (
    <div className="w-full mx-auto">
      <div className="w-full min-h-screen flex  flex-wrap justify-center items-center p-4 bg-neutral-100">
        <div className="w-96 bg-white rounded-lg shadow-xl py-20 px-10">
          <div className="w-full text-center">
            <img src={logo} alt="로고" className="w-60 mb-16" />
            <button
              onClick={onGoogleLoginClick}
              className="mx-auto bg-slate-900 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 text-white font-semibold h-12 px-6 rounded-lg w-full flex items-center justify-center sm:w-auto"
            >
              Google 계정으로 로그인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
