import React, { createContext, useContext, useState } from 'react';
import { type User } from 'interface/common';

interface AuthContextInterface {
  isLoggedIn: boolean;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  userData: User;
  setUserData: React.Dispatch<React.SetStateAction<User>>;
  token: string;
  setToken: React.Dispatch<React.SetStateAction<string>>;
}

export const AuthContext = createContext<AuthContextInterface>({
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  userData: null,
  setUserData: () => {},
  token: null,
  setToken: () => {},
});

interface AuthProviderProps {
  children: React.ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState(null);
  const value = {
    isLoggedIn,
    setIsLoggedIn,
    userData,
    setUserData,
    token,
    setToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function IsLoggedInState() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('Cannot find AuthContext');
  }
  return context.isLoggedIn;
}
