import axios from 'axios';
import { defaultApi } from 'services/api.helpers';

export const instance = axios.create({
  baseURL: defaultApi,
  // headers: {
  //   "Content-Type": "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // },
  // timeout: 2000,
});

// export const authInstance = axios.create({
//   baseURL: defaultApi,
//   headers: {
//     Authorization: token,
//     "Content-Type": "application/json",
//     "Access-Control-Allow-Origin": "*",
//   },
// });
