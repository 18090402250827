import { LeftOutlined } from '@ant-design/icons';
import { Button, Form, Input, Radio, Upload, message } from 'antd';
import { instance } from 'apis';
import Editor from 'components/common/Editor';
import { ErrorModal } from 'components/common/Modal';
import { AuthContext } from 'context/AuthContext';
import { useContext, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CDN, defaultApi } from 'services/api.helpers';
import axios from 'axios';

const POIRegister = () => {
  const context = useContext(AuthContext);
  const { eventId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [form] = Form.useForm();
  const [description, setDescription] = useState<string>('');

  const selectedContentValue = Form.useWatch('selectedContent', form);
  const audioContentValue = Form.useWatch('audioContent', form);

  const success = (content: string) => {
    message.success(content);
  };

  async function handleFormSubmit(values: any) {
    const addData = {
      content: {
        title: values.title,
        langCode: 'ko_KR',
        audioUrl: selectedContentValue === 'audio' ? values.audioUrl : '',
        arUrl: selectedContentValue === 'ar' ? values.arUrl : '',
        addressName: values.addressName,
        address: values.address,
        description: description,
      },
      location: {
        latitude: values.latitude,
        longitude: values.longitude,
      },
      eventId: eventId,
    };

    await instance
      .post(`/web/poi`, addData, {
        headers: {
          Authorization: context.token,
        },
      })
      .then(() => {
        success('POI 등록이 완료됐습니다.');
        navigate(-1);
      })
      .catch((error) => {
        console.log(error);
        ErrorModal(error.response.data.errorCode, error.response.data.message);
      });
  }

  const customActionRequest = (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;

    // if (fileList.length !== 0) {
    //   // 파일이 이미 있을때는 재업로드시 다시 로딩상태로 변경  - register에선 필요없음
    //   setFileList([
    //     {
    //       uid: '1',
    //       // 파일명 파싱 코드 by Sarc
    //       name: 'uploading...',
    //       status: 'uploading',
    //       url: ``,
    //       percent: 0,
    //     },
    //   ]);
    // }
    const fmData = new FormData();
    const config = {
      headers: {
        Authorization: context.token,
        'content-type': 'multipart/form-data',
      },
      onUploadProgress: (event: any) => {
        // console.log((event.loaded / event.total) * 100);

        // 진행상황 체크 - register에선 필요없음
        // for (let i = 10; i < 100; i += 10) {
        //   // progress는 업로드중 계속터지는 이벤트라 10퍼센트에 한번씩 일어나서 로딩바를 수정하도록 수정
        //   if ((event.loaded / event.total) * 100 > i) {
        //     setFileList([
        //       {
        //         uid: '1',
        //         name: 'uploading...',
        //         status: 'uploading',
        //         url: ``,
        //         percent: (event.loaded / event.total) * 100,
        //       },
        //     ]);
        //   }
        // }
        onProgress({ percent: (event.loaded / event.total) * 100 }, file);
      },
    };
    fmData.append('file', file, encodeURIComponent(file.name));
    axios
      .post(defaultApi + '/uploads', fmData, config)
      .then((res) => {
        // 폼 audioUrl 교체
        form.setFieldsValue({
          audioUrl: `${CDN}${res.data.url}`,
        });
        // 파일명 체크
        const _fileName =
          `${CDN}${res.data.url}` && `${CDN}${res.data.url}` !== ''
            ? decodeURIComponent(`${CDN}${res.data.url}`).substring(
                decodeURIComponent(`${CDN}${res.data.url}`).lastIndexOf('/') +
                  1,
              )
            : 'audio_file.mp3';
        // 파일리스트 교체 - register에선 필요없음
        // setFileList([
        //   {
        //     uid: '1',
        //     // 파일명 파싱 코드 by Sarc
        //     name: _fileName,
        //     status: 'done',
        //     url: `${CDN}${res.data.url}`,
        //   },
        // ]);

        message.success(`${_fileName} 파일 업로드 성공`);
        onSuccess(file);
        // console.log('response-', res);
      })
      .catch((err: any) => {
        const error = new Error('Some error');
        // 폼 audioUrl 교체
        form.setFieldsValue({
          audioUrl: '',
        });
        // 파일리스트 교체 - register에선 필요없음
        // setFileList([
        //   {
        //     uid: '1',
        //     // 파일명 파싱 코드 by Sarc
        //     name: '업로드 실패!',
        //     status: 'error',
        //     response: '업로드 실패! 다시 시도해주세요.', // custom error message to show
        //     url: '',
        //   },
        // ]);

        message.error(`파일 업로드 실패! 다시 시도해주세요.`);
        onError({ event: error, err });
      });
  };

  return (
    <div className="flex flex-col bg-white shadow-xl w-full max-h-full rounded-xl">
      <div className="px-6 py-8">
        <div className="flex gap-2">
          <LeftOutlined
            className="text-gray-700 font-bold text-2xl"
            onClick={() => navigate(-1)}
          />
          <div>
            <h5 className="text-4xl font-bold text-gray-700 font-GyeonggiCheonnyeon align-middle">
              POI 등록
            </h5>
            <p className="absolute text-lg text-gray-500 leading-8">
              {location.state.title}
            </p>
          </div>
        </div>
      </div>
      <Form
        form={form}
        name="POI-Data"
        layout="vertical"
        className="flex flex-col max-h-full overflow-y-auto px-4 pb-4"
        onFinish={handleFormSubmit}
        initialValues={{
          selectedContent: 'text',
          audioContent: '',
        }}
      >
        <div className="p-2 overflow-y-auto grid grid-cols-3 gap-x-4 border-y">
          <Form.Item
            name="title"
            label="POI명"
            rules={[
              {
                required: true,
                message: 'POI명을 입력하세요.',
              },
            ]}
          >
            <Input placeholder="POI명" />
          </Form.Item>
          <Form.Item
            name="latitude"
            hidden={location.state.isSingle}
            label="위도"
            rules={[
              {
                required: !location.state.isSingle,
                message: '위도를 입력하세요.',
              },
            ]}
          >
            <Input placeholder="위도" />
          </Form.Item>
          <Form.Item
            name="longitude"
            hidden={location.state.isSingle}
            label="경도"
            rules={[
              {
                required: !location.state.isSingle,
                message: '경도을 입력하세요.',
              },
            ]}
          >
            <Input placeholder="경도" />
          </Form.Item>
          <Form.Item
            name="addressName"
            label="주소명"
            hidden={location.state.isSingle}
            className="col-start-1"
            rules={[
              {
                required: !location.state.isSingle,
                message: '주소명을 입력하세요.',
              },
            ]}
          >
            <Input placeholder="주소명" />
          </Form.Item>
          <Form.Item
            name="address"
            hidden={location.state.isSingle}
            label="주소"
            rules={[
              {
                required: !location.state.isSingle,
                message: '주소를 입력하세요.',
              },
            ]}
          >
            <Input placeholder="주소" />
          </Form.Item>
          <Form.Item name="selectedContent" label="컨텐츠 유형" required>
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="text">텍스트</Radio.Button>
              <Radio.Button value="ar">AR 가이드</Radio.Button>
              <Radio.Button value="audio">오디오 가이드</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="arUrl"
            label="AR 가이드(URL)"
            className="col-start-1"
            rules={[
              {
                required: selectedContentValue === 'ar',
                message: 'AR 가이드(URL)를 입력해주세요.',
              },
            ]}
            hidden={selectedContentValue !== 'ar'}
          >
            <Input placeholder="AR 가이드(URL)" />
          </Form.Item>
          <Form.Item
            label="오디오 가이드"
            hidden={selectedContentValue !== 'audio'}
            required={selectedContentValue === 'audio'}
            className="col-span-2"
          >
            <Form.Item name="audioContent" className="inline-block w-32">
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="file">파일</Radio.Button>
                <Radio.Button value="url">URL</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              hidden={audioContentValue !== 'file'}
              className={audioContentValue === 'file' && 'inline-block'}
            >
              <Upload
                name="file"
                customRequest={customActionRequest}
                className="flex gap-4"
                maxCount={1}
              >
                <Button>가이드 업로드</Button>
              </Upload>
            </Form.Item>
            <Form.Item
              name="audioUrl"
              hidden={audioContentValue !== 'url'}
              className={
                audioContentValue === 'url' &&
                'inline-block w-[calc(100%-128px)]'
              }
            >
              <Input placeholder="오디오 가이드 URL" />
            </Form.Item>
          </Form.Item>
          <Form.Item
            name="description"
            label="설명"
            className="col-start-1 col-span-3"
          >
            <Editor description={description} setDescription={setDescription} />
          </Form.Item>
        </div>
        <div className="flex justify-end gap-4 pt-4">
          <Button danger onClick={() => navigate(-1)}>
            취소
          </Button>
          <Button type="primary" htmlType="submit" className="bg-[#1677ff]">
            등록
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default POIRegister;
