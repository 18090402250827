import { useContext, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Radio,
  Segmented,
  message,
} from 'antd';
import DatePicker from 'react-datepicker';
import { instance } from 'apis';
import { EventContents, LangCode, POI } from 'interface/common';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from 'context/AuthContext';
import { ko } from 'date-fns/esm/locale';

import { ErrorModal } from 'components/common/Modal';
import ThumbnailUpload from 'components/event/ThumbnailUpload';
import POITable from './poi/POITable';

import 'react-datepicker/dist/react-datepicker.css';
import { LeftOutlined } from '@ant-design/icons';

const ContentEdit = () => {
  const { eventId } = useParams();
  const context = useContext(AuthContext);
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [thumbnailUrl, setThumbnailUrl] = useState<string>('');
  const [contentsData, setContentsData] = useState<EventContents[]>([]);
  const [selectedLangCode, setSelectedLangCode] = useState<LangCode>('ko_KR');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [poiData, setPoiData] = useState<POI[]>(null);

  const success = (content: string) => {
    message.success(content);
  };

  const getEventData = useMemo(() => {
    return (eventId: string) => {
      instance
        .get(`/web/events/${eventId}`, {
          headers: {
            Authorization: context.token,
          },
        })
        .then((result) => {
          const data = result.data.result;
          const defaultThumbnail = data.contents[1].thumbnail;
          setThumbnailUrl(defaultThumbnail);
          setContentsData(data.contents);
          setStartDate(new Date(data.startDateTime));
          setEndDate(new Date(data.endDateTime));
          setPoiData(data.pois);
          form.setFieldsValue({
            id: data.id,
            contentId: data.contents[1].id,
            title: data.contents[1].title,
            description: data.contents[1].description,
            state: data.state,
            estimatedTime: data.estimatedTime,
            estimatedDistance: data.estimatedDistance,
            latitude: data.location.latitude,
            longitude: data.location.longitude,
            // startDateTime: data.startDateTime,
            // endDateTime: data.endDateTime,
            isSingle: data.isSingle,
            isPremium: data.isPremium,
            // isLock: data.isLock,
            // externalLink: data.externalLink,
            // url: data.url,
            // password: data.password,
          });
        })
        .catch((error) => {
          ErrorModal(
            error.response.data.errorCode,
            error.response.data.message,
          );
        });
    };
  }, [context.token, form]);

  useEffect(() => {
    if (eventId !== null) {
      getEventData(eventId);
    }
  }, [eventId, getEventData]);

  const selectLang = (langCode: LangCode) => {
    setSelectedLangCode(langCode);
    const langData = contentsData.filter((item) => item.langCode === langCode);
    const langThumbnail = langData[0].thumbnail;
    setThumbnailUrl(langThumbnail);
    form.setFieldsValue({
      contentId: langData[0].id,
      title: langData[0].title,
      description: langData[0].description,
    });
  };

  const onCouponClick = () => {
    navigate(`/event/${eventId}/coupon`);
  };

  async function handleFormSubmit(values: any) {
    const content = {
      id: values.contentId,
      langCode: selectedLangCode,
      thumbnail: thumbnailUrl,
      title: values.title,
      description: values.description,
    };
    const editData = {
      content: content,
      location: {
        latitude: values.latitude,
        longitude: values.longitude,
      },
      state: values.state,
      estimatedTime: values.estimatedTime,
      estimatedDistance: values.estimatedDistance,
      startDateTime: values.startDateTime,
      endDateTime: values.endDateTime,
      isSingle: values.isSingle,
      isPremium: values.isPremium,
    };

    await instance
      .put(`/web/events/${values.id}`, editData, {
        headers: {
          Authorization: context.token,
        },
      })
      .then(() => {
        const updatedData = contentsData.map((item) => {
          if (item.id === values.contentId) {
            return { ...item, ...content };
          }
          return item;
        });
        setContentsData(updatedData);
        success('수정이 완료됐습니다.');
      })
      .catch((error) => {
        ErrorModal(error.response.data.errorCode, error.response.data.message);
      });
  }

  async function handleDelete() {
    await instance
      .delete(`/web/events/${eventId}`, {
        headers: {
          Authorization: context.token,
        },
      })
      .then(() => {
        success('이벤트를 삭제했습니다.');
        navigate('/event');
      })
      .catch((error) => {
        ErrorModal(error.response.data.errorCode, error.response.data.message);
      });
  }

  async function handleDataPaste(langCode: LangCode) {
    const values = form.getFieldsValue(['id', 'title', 'description']);

    const pasteData = {
      parentId: values.id,
      langCode: langCode,
      title: values.title,
      description: values.description,
      thumbnail: thumbnailUrl,
    };

    await instance
      .put('/web/events', pasteData, {
        headers: {
          Authorization: context.token,
        },
      })
      .then(() => {
        contentsData.forEach((item) => {
          if (item.langCode === langCode) {
            item.title = values.title;
            item.description = values.description;
            item.thumbnail = thumbnailUrl;
          }
        });
        success(
          `영어 콘텐츠를 ${
            langCode === 'ja_JP' ? '일본어' : '중국어'
          } 콘텐츠로 복사했습니다.`,
        );
      })
      .catch((error) => {
        ErrorModal(error.response.data.errorCode, error.response.data.message);
      });
  }

  return (
    <div className="flex flex-col bg-white shadow-xl w-full max-h-full rounded-xl">
      <div className="px-6 py-8 flex gap-6">
        <div className="flex gap-2">
          <LeftOutlined
            className="text-gray-700 font-bold text-2xl"
            onClick={() => navigate('/event')}
          />
          <h5 className="text-4xl font-bold text-gray-700 font-GyeonggiCheonnyeon align-middle">
            이벤트 정보
          </h5>
        </div>
        <Segmented
          size="large"
          options={[
            {
              label: '한국어',
              value: 'ko_KR',
              className: 'px-4',
            },
            {
              label: '영어',
              value: 'en_US',
              className: 'px-4',
            },
            {
              label: '일본어',
              value: 'ja_JP',
              className: 'px-4',
            },
            {
              label: '중국어',
              value: 'zh_CN',
              className: 'px-4',
            },
          ]}
          value={selectedLangCode}
          onChange={(value: LangCode) => {
            selectLang(value);
          }}
        />
        {selectedLangCode === 'en_US' && (
          <div className="flex gap-2">
            <Button onClick={() => handleDataPaste('ja_JP')}>
              영어 → 일본어
            </Button>
            <Button onClick={() => handleDataPaste('zh_CN')}>
              영어 → 중국어
            </Button>
          </div>
        )}
        <Button type="primary" className="bg-[#1677ff]" onClick={onCouponClick}>
          쿠폰현황
        </Button>
      </div>
      <Form
        form={form}
        name="content-edit"
        colon={false}
        layout="vertical"
        className="flex flex-col max-h-full overflow-y-auto px-4 pb-4"
        onFinish={handleFormSubmit}
      >
        <div className="p-2 overflow-y-auto border-y">
          <div className="grid grid-cols-6 grid-rows-7 gap-x-4">
            <Form.Item className="col-span-2 row-span-5" label="썸네일">
              <ThumbnailUpload
                thumbnailUrl={thumbnailUrl}
                setThumbnailUrl={setThumbnailUrl}
              />
            </Form.Item>
            <Form.Item name="id" label="ID" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="contentId" label="콘텐츠 ID" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              name="title"
              label="이벤트명"
              className="col-span-2"
              rules={[
                {
                  required: true,
                  message: '이벤트명을 입력해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="state" label="상태" required>
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="OPEN">개시</Radio.Button>
                <Radio.Button value="CLOSE">종료</Radio.Button>
                <Radio.Button value="HIDE">숨김</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item name="isPremium" label="Premium" required>
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={true}>YES</Radio.Button>
                <Radio.Button value={false}>No</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="estimatedTime"
              label="예상소요시간"
              className="col-start-3"
              rules={[
                {
                  required: true,
                  message: '예상소요시간을 입력해주세요.',
                },
              ]}
            >
              <Input suffix="hr" />
            </Form.Item>
            <Form.Item
              name="estimatedDistance"
              label="예상소요거리"
              rules={[
                {
                  required: true,
                  message: '예상소요거리를 입력해주세요.',
                },
              ]}
            >
              <Input suffix="km" />
            </Form.Item>
            <Form.Item
              name="latitude"
              label="위도"
              rules={[
                {
                  required: true,
                  message: '위도를 입력해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="longitude"
              label="경도"
              rules={[
                {
                  required: true,
                  message: '경도를 입력해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="startDateTime" label="시작일">
              <DatePicker
                locale={ko}
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                dateFormat="yyyy-MM-dd a h:mm"
                showTimeSelect
                className="border h-10 py-2 px-4 rounded-md border-[#d9d9d9] hover:border-[#4096ff] transition duration-200 focus:border-[#4096ff] outline-none"
              />
            </Form.Item>
            <Form.Item name="endDateTime" label="종료일">
              <DatePicker
                locale={ko}
                selected={endDate}
                onChange={(date) => {
                  setEndDate(date);
                }}
                dateFormat="yyyy-MM-dd a h:mm"
                showTimeSelect
                className="border h-10 py-2 px-4 rounded-md border-[#d9d9d9] hover:border-[#4096ff] transition duration-200 focus:border-[#4096ff] outline-none"
              />
            </Form.Item>
            <Form.Item name="isSingle" label="마커노출" required>
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={true}>이벤트마커</Radio.Button>
                <Radio.Button value={false}>POI마커</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="description"
              label="설명"
              className="col-span-4 row-span-2"
            >
              <Input.TextArea spellCheck={false} rows={5} />
            </Form.Item>
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex justify-start">
              <Button
                type="primary"
                className="bg-[#1677ff]"
                onClick={() =>
                  navigate(`/event/${eventId}/poi/register`, {
                    state: {
                      title: form.getFieldValue('title'),
                      isSingle: form.getFieldValue('isSingle'),
                    },
                  })
                }
              >
                POI 등록
              </Button>
            </div>
            <POITable
              title={contentsData[1]?.title}
              poiData={poiData}
              setPoiData={setPoiData}
            />
          </div>
        </div>
        <div className="flex justify-end gap-4 pt-4">
          <Popconfirm
            title="이벤트 삭제"
            description="정말 이 이벤트를 삭제하시겠습니까?"
            onConfirm={handleDelete}
            okButtonProps={{ danger: true }}
            okText="삭제"
            cancelText="취소"
          >
            <Button danger>삭제</Button>
          </Popconfirm>
          <Button htmlType="submit" type="primary" className="bg-[#1677ff]">
            수정
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ContentEdit;
