import { useContext, useEffect, useState } from 'react';

import { instance } from 'apis';
import { AuthContext } from 'context/AuthContext';

import { Event } from 'interface/common';

import { ErrorModal } from 'components/common/Modal';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import EventTable from 'components/event/EventTable';
import { auth } from '../../services/firebase';

const EventManage = () => {
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<Event[]>(null);

  function checkLoggedIn() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
        navigate('/login');
      }
    });
  }

  useEffect(() => {
    checkLoggedIn();
  }, []);

  function getEventsData() {
    instance
      .get('/web/events', {
        headers: {
          Authorization: context.token,
        },
      })
      .then((result) => {
        setData(result.data.result);
        setIsLoading(false);
      })
      .catch((error) => {
        ErrorModal(error.code, error.message);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getEventsData();
  }, []);

  const handleButtonClick = () => {
    navigate('/event/register');
  };

  return (
    <div className="bg-white shadow-xl w-full rounded-xl">
      <div className="px-6 py-8 flex justify-between">
        <h5 className="text-4xl font-bold text-gray-700 font-GyeonggiCheonnyeon">
          이벤트 관리
        </h5>
        <Button
          type="primary"
          onClick={handleButtonClick}
          className="bg-[#1677ff] text-white hover:bg-white"
        >
          이벤트 등록
        </Button>
      </div>
      <div className="px-6 pb-6">
        <EventTable data={data} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default EventManage;
