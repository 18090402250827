import { useContext, useEffect, useState } from 'react';
import { instance } from 'apis';
import { Coupon } from 'interface/common';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from 'context/AuthContext';
import { ErrorModal } from 'components/common/Modal';

import 'react-datepicker/dist/react-datepicker.css';
import { LeftOutlined } from '@ant-design/icons';
import Table, { ColumnsType } from 'antd/es/table';
import { Button, Input } from 'antd';

const EventCoupon = () => {
  const { eventId } = useParams();
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<Coupon[]>(null);
  const [usageData, setUsageData] = useState<{
    notUsed: number;
    usedCnt: number;
  }>({ notUsed: 0, usedCnt: 0 });
  const [couponNumber, eetCouponNumber] = useState<string>('');
  const [couponId, setCouponId] = useState<string>('ALL');
  const [tableHeight, setTableHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setTableHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function handleButtonClick() {
    setCouponId(couponNumber);
  }

  function getCouponData() {
    instance
      .get(`/get-coupon-list/${eventId}/${couponId}`, {
        headers: {
          Authorization: context.token,
        },
      })
      .then((result) => {
        setData(result.data.results);
        setIsLoading(false);
      })
      .catch((error) => {
        ErrorModal(error.code, error.message);
        setIsLoading(false);
      });
  }

  function getCouponUsageStatus() {
    instance
      .get(`/get-coupon-usage-status/${eventId}`, {
        headers: {
          Authorization: context.token,
        },
      })
      .then((result) => {
        setUsageData(result.data);
        setIsLoading(false);
      })
      .catch((error) => {
        ErrorModal(error.code, error.message);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getCouponUsageStatus();
  }, []);

  useEffect(() => {
    getCouponData();
  }, [couponId]);

  const columns: ColumnsType<Coupon> = [
    {
      title: '쿠폰번호',
      dataIndex: 'serialCode',
      align: 'center',
    },
    {
      title: '사용여부',
      dataIndex: 'useYN',
      align: 'center',
    },
    {
      title: '사용자 Email',
      dataIndex: 'email',
      align: 'center',
      render(value) {
        return value ? value : '-';
      },
    },
    {
      title: '사용일시',
      dataIndex: 'usedDate',
      align: 'center',
      render(value) {
        return value !== ' ' ? value : '-';
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white shadow-xl w-full max-h-full rounded-xl">
      <div className="px-6 py-8 flex gap-6">
        <div className="flex gap-2">
          <LeftOutlined
            className="text-gray-700 font-bold text-2xl"
            onClick={() => navigate('/event')}
          />
          <h5 className="text-4xl font-bold text-gray-700 font-GyeonggiCheonnyeon align-middle">
            쿠폰현황
          </h5>
        </div>
      </div>
      <div className="px-6 pb-6 flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <div className="flex gap-4 items-center">
            <span className="text-xl">미사용 / 사용 :</span>
            <Input
              style={{ width: '200px' }}
              height={40}
              value={`${usageData?.notUsed} / ${usageData?.usedCnt}`}
            />
          </div>
          <div className="flex gap-4 items-center">
            <span className="text-xl">쿠폰 검색 :</span>

            <Input
              style={{ width: '200px' }}
              height={40}
              onChange={(e) => {
                if (e.target.value === '') {
                  return eetCouponNumber('ALL');
                }
                eetCouponNumber(e.target.value);
              }}
            />

            <Button
              type="primary"
              onClick={handleButtonClick}
              className="bg-[#1677ff] text-white hover:bg-white"
            >
              검색
            </Button>
          </div>
        </div>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{
            x: true,
            y: tableHeight > 705 ? tableHeight - 400 : undefined,
          }}
        />
      </div>
    </div>
  );
};

export default EventCoupon;
